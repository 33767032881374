.dialog-center {
  margin-left: 400px;
  margin-right: 400px;
}
.process-payments {
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 80vh;
  .text-info {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #686868;
    padding-top: 11px;
    padding-bottom: 34px;
  }
  .button-resize {
    display: flex !important;
    align-self: flex-start !important;
    margin: 0;
    font-size: 1.2rem;
    width: auto;
    text-transform: none;
    font-weight: 500;
    color: $white;
  }
  .button-section {
    background-color: white;
    border-radius: 10px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }
  .row-steps {
    width: 80%;
    background-color: $back-gray;
    align-self: center;
    border-radius: 10px;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    background: rgba(40, 48, 144, 0.2);
    border-radius: 7px;
    background: white;
    
    .option-step {
      padding: 0.9rem;
      width: 30%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      margin-right: 15px;
      background: rgba(40, 48, 144, 0.2);
      border-radius: 7px;
      color: #000000;
    }
    .back-blue {
      background-color: $back-blue;
      color: $white;
      border-radius: 7px;
    }
  }
  .payment-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .rounded-section {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      font-weight: 700;
      border-radius: 50%;
      background-color: #aaa;
      margin-bottom: 30px;
      .confirmation-amount {
        margin-bottom: 20px;
        font-size: 4rem;
      }
      .confirmation-method {
        font-size: 0.6rem !important;
      }
    }
    .message {
      margin-bottom: 1.2rem;
      margin-top: 0.2rem;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      color: #686868;
    }
    .amount-send {
      width: 30vw;
      justify-content: space-between;
      margin-bottom: 20px;
      .amount-money {
        flex: 1;
        margin-right: 10px;
        width: 200px;
        height: 4.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6e6;
        border-radius: 10px;
        word-wrap: break-word;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        color: #4BAC56; 
      }
      .money-select {
        flex: 1;
        background-color: #010c4c;
        width: 200px;
        height: 4.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        text-align: center;
      }
    }
    .calculator {
      display: flex;
      flex:1;
      flex-direction: column;
      padding: 30px;
      width: 27vw;
      height: 50vw;
      border-radius: 10px;
      background: #FFFFFF;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
      border-radius: 13px;

      .calculator-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9vw;
        height: 4.2vw;
        cursor: pointer;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 25px;
        line-height: 22px;
        text-align: center;
        color: #000000;
      }
      
      .top-left {
        border-top-left-radius: 10px;
      }
      .top-right {
        border-top-right-radius: 10px;
      }
      .bottom-left {
        border-bottom-left-radius: 10px;
      }
      .bottom-right {
        border-bottom-right-radius: 10px;
      }
      .calculator-number:active {
        background-color: $text-gray;
      }
    }
    .switch-row {
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      .switch-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 13px;
        color: #686868;
        margin-right: 10px;
      }
    }
    .btn-amount {
      background-color: $back-blue;
      color: $white;
      border-radius: 10px;
      padding: 0.8rem;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2.5rem;
    }
    .payment-methods {
      width: 70%;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 35px;
      .payment-options {
        width: 49.2%;
        background-color: $back-gray;
        border-radius: 10px;
        align-items: center;
        padding: 0.6rem;
        margin-bottom: 1rem;
        cursor: pointer;
        .payment-icon {
          width: 3.5rem;
          margin-right: 1rem;
        }
        .text-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .payment-title {
            font-size: 1.2rem;
            font-weight: 500;
          }
          .payment-detail {
            font-size: 1rem;
          }
        }
      }
      .border-blue {
        border: 2.5px solid $back-blue;
      }
    }
    .payment-step3 {
      width: 80%;
      .payment-chivo {
        width: 100%;
      }
      .payment-options {
        background-color: $white;
        border: 2px solid $back-gray;
      }
      .border-blue {
        background-color: $back-gray;
      }
      .qr-code-box {
        background-color: $back-gray;
        border-radius: 10px;
        flex: .5;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .padding-qr {
          padding: 10%;
        }
      }
      .info-box {
        width: 17vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .currencyType-txt{
          align-self: center;
          padding-bottom: 0.8rem;
          color: white;
          padding: 7px;
          width: 22%;
          font-family: 'Lato';
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 16px;
        }
        .timer {
           margin-top: 7px;
           margin-bottom: 20px;
           
          .timer-number {
            font-family: 'Lato';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 16px;
            color: #283090;
          }
        }
      }
      .total-send {
        border-radius: 10px;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 1rem;
        width: 100%;
        font-size: 2rem;
        font-weight: 600;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        color: #000000;
      }
      .amount-btc {
        font-weight: 600;
      }
    }
    .wallet-container{
      display: flex;
      margin-left: auto;
      padding-right: 8%;
      align-items: center;
    }
    .wallet-txt{    
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
    }

    .number-wallet {
      background: rgba(40, 48, 144, 0.2);
      border-radius: 15px;
      align-items: center;
      padding: 0.5rem 1rem;
      // margin-bottom: 1rem;
      // margin-top: 0.5rem;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .step2-margin {
    margin-bottom: 5rem;
  }
  .row-btn {
    justify-content: space-between;
    align-self: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    .btn-steps {
      padding: 0.4rem 2.2rem;
      font-size: 1.2rem;
      font-weight: 400;
      background-color: $back-blue;
      color: $white;
      border-radius: 10px;
      text-transform: none;
      cursor: pointer;
    }
    .btn-gray {
      background-color: $back-gray;
      color: $black;
    }
    .btn-end {
      align-self: flex-end;
    }
  }
  .row-end {
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}
.payment-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  .rounded-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    font-weight: 700;
    border-radius: 50%;
    background-color: #aaa;
    margin-bottom: 30px;
    .confirmation-amount {
      margin-bottom: 20px;
      font-size: 4rem;
    }
    .confirmation-method {
      font-size: 0.6rem !important;
    }
  }
  .message {
    font-size: 1.1rem;
    margin-bottom: 1.2rem;
    margin-top: 0.2rem;
  }
}
@include breakpoint($m-desktop) {
  .process-payments {
    .payment-step {
      .payment-step3 {
        width: 65%;
        .info-box {
          width: 23vw;
          .timer {
            width: 25vw;
          }
        }


      }

    }
  }

  .transactions-card{
    .square{
      margin-left: 2px;
    }

    .desc{
      width: 115px;
    }

    .view{
      margin-left: 165px;
    }
  } 

}

@include breakpoint($desktop) {
  .transactions-card{
    .amount{
      margin-left: 5px ;
    }
    .square{
      margin-left: 5px ;
    }
  } 

  .transactions-card {
    .desc{
      width: 115px !important;
      margin-left: 12px 
    }

    .view{
      margin-left: 124px;
    }

    .square{
      margin-left: 0px;
    }

    .amount{
      width: 133px !important;
    }
  }

}

@include breakpoint($lg-desktop){
  .transactions-card{
    .view{
      margin-left: 165px;
    }
  }

 

}



@include breakpoint($sm-desktop) {
  .dialog-center {
    margin-left: 250px;
    margin-right: 250px;
  }
  .process-payments {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
    .payment-step {
      .calculator {
        ons-row {
          height: 25%;
          .calculator-number {
            flex: 1 !important;
          }
        }
      }
      .amount-send {
        width: 30vw;
        .amount-money,
        .money-select {
          width: 14.2vw;
          height: 6.2vw;
        }
      }
      .switch-row {
        margin-bottom: 1rem;
      }
    }
  }
}

@include breakpoint($tablet) {
  .dialog-center {
    margin-left: 200px;
    margin-right: 200px;
  }
  .process-payments {
    .payment-step {
      .calculator {
        width: 33vw;
        .calculator-number {
          height: 7.2vw;
        }
      }
      .amount-send {
        width: 40vw;
        .amount-money,
        .money-select {
          width: 19.2vw;
        }
      }
    }
    .row-end {
      justify-content: center;
    }
    .row-btn {
      justify-content: space-between;
    }
    .row-btn.btn-step3 {
      justify-content: center !important;
    }

    .transactions-card{
      .title{
        margin-right:0px
      }
    } 
  }
}

@include breakpoint($sm-tablet) {
  .dialog-center {
    margin-left: 10px;
    margin-right: 10px;
  }
  .process-payments {
    .payment-step {
      .calculator {
        width: 40vw;
        .calculator-number {
          height: 9.2vw;
          font-size: 1.6rem;
        }
      }

      .payment-methods {
        width: 90%;
        .payment-options {
          .text-box {
            .payment-title {
              font-size: 1rem;
            }
            .payment-detail {
              font-size: 0.8rem;
            }
          }
          .payment-icon {
            width: 2.8rem;
          }
        }
      }


      .amount-send {
        width: 50vw;
        .amount-money,
        .money-select {
          width: 24.2vw;
          height: 9.2vw;
          font-size: 1.6rem;
        }
      }
      .btn-amount {
        font-size: 1.6rem;
      }
    }
    .row-steps {
      width: 90%;
      .option-step {
        font-size: 1rem;
      }
    }
  }
}

@include breakpoint($lg-phone) {
  .process-payments {
    .payment-step {
      .calculator{
        width: 41vh;
      }
      .payment-step3 {
        .info-box {
          width: 100%;
          .timer {
            width: 100%;
          }
          .total-send {
            width: 95%;
          }
        }
        .qr-code-box {
          margin-bottom: 15px;
        }
        .amount-btc {
          margin-bottom: 15px;
        }
      }
      .amount-send {
        width: 60vw;
        .amount-money,
        .money-select {
          width: 28.2vw;
        }
      }

      .number-wallet {
        // width: 70%;
        font-size: 14px;
      }
    }
    .row-steps {
      width: 95%;
    }
  }
}

@include breakpoint($m-phone) {
  .process-payments {
    .payment-step {
      .calculator {
        width: 57vw;
        .calculator-number {
          height: 10.2vw;
        }
      }

      .amount-send {
        width: 70vw;
        .amount-money,
        .money-select {
          width: 34.2vw;
          height: 12.2vw;
        }
      }
    }
    .row-steps {
      width: 100%;
    }
  }
  
  .transactions-card{
    .amount{
      width: 105px;
    }

    .square{
      margin-left: 5px;
    }
  }



}

@include breakpoint($phone) {

  .process-payments {
    .payment-step {
      .calculator {
        width: 42vh;
        .calculator-number {
          height: 10.2vw;
        }
      }


    }
    


  }

  .mt-10px {
    margin-top: 5px;
  } 

  .transactions-card{
    .desc{
      margin-bottom: 19px;

      .date{
        font-size: 13px;
      }

    } 

    .amount{
      font-size: 12px;
      width: 91px;
    }

      .title{
        margin-right: 0px;
      }

    .view{
      margin-left: 50px;
    }



    
  }

 

  .process-payments {
    .button-section,
    .text-info {
      font-size: 1rem;
    }
    .payment-step {
      .payment-methods {
        .payment-options {
          .text-box {
            .payment-title {
              font-size: 0.8rem;
            }
            .payment-detail {
              font-size: 0.6rem;
            }
          }
          .payment-icon {
            width: 2rem;
          }
        }
      }

      .amount-send {
        width: 80vw;
        .amount-money,
        .money-select {
          width: 38.2vw;
          height: 14.2vw;
        }
      }
      .message {
        font-size: 0.9;
      }
      .btn-amount {
        font-size: 1rem;
      }
    }
    .row-steps {
      .option-step {
        font-size: 0.8rem;
      }
    }
    .row-btn .btn-steps {
      font-size: 0.8rem;
    }
  }
}

@include breakpoint($sm-phone) {
  .process-payments {
    .row-btn .btn-steps {
      font-size: 0.8rem;
    }
    .payment-step {
      .calculator {
         width: 36vh;
        }
      .payment-methods {
        width: 100%;
        .payment-options {
          .payment-icon {
            width: 1.4rem;
          }
        }
      }

    }
  }

  .transactions-card{
    .desc{
      .date{
        font-size: 13px;
      }
      title{
        margin-right: 40px;
      }

    } 
    .square{
      margin-left: 5px;
    }

    .amount{
      width: 120px;
    }

    .view{
      margin-left: 48px;
    }
  }

}