.tofriend-content {
  position: relative;
  display: flex;
  height: auto;
  min-height: calc(100vh - 204px);
  .title {
    font-size: 30px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
  }
  .input-col {
    display: flex;
    flex-direction: column;
    width: 50vw !important;
    .title-item{
      flex: 1;
      text-align: left;
    }
    .value-item {
      flex: 1;
      text-align: left;
      .text-input {
        height: auto;
        color: #00003A;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .row-item {
      width: 60%;
      align-self: flex-end;
      justify-content: space-between;
      background-color: $back-gray;
      padding: 0.8rem;
      margin: 0.2rem;
      margin-right: 20%;
      border-radius: 10px;
      .text-input--material {
        background-image: none;
      }
    }
    .row-size {
      width: 45%;
      padding: 0.8rem;
      margin: 0.2rem;
      border-radius: 10px;
      background-color: $back-gray;
      .color-box {
        height: 30px;
        width: 30px;
      }
      .name-option {
        margin-left: 10px;
        align-self: center;
      }
    }
    .row-margin-top {
      margin-top: 30px;
    }
    .row-amount {
      justify-content: center;
      .switch--material {
        height: 32px;
      }
      .switch {
        width: 120px !important;
        margin-bottom: 20px;
        .switch--material__toggle,
        .switch__toggle {
          margin-top: 0;
          box-shadow: inset 0 0 0 2px #d4d6e9;
          background-color: #D4D6E9;
          height: 32px;
          .switch--material__handle,
          .switch__handle {
            margin-top: 0;
            top: 0 !important;
            height: 32px;
            width: 65px !important;
            right: 55px !important;
            background-color: #00003A !important;
          }
          .switch__handle::after {
            content: "USD";
            color: white;
            padding-left: 15px;
            vertical-align: sub;
            font-size: 18px;
          }
        }
        .switch__toggle::after {
          content: "BTC";
          color: white;
          padding-left: 74px;
          vertical-align: sub;
          font-size: 18px;
        }
        :checked+.switch--material__toggle::after,
        :checked+.switch__toggle::after {
          content: "USD";
          padding-left: 14px;
        }
        :checked+.switch--material__toggle>.switch--material__handle,
        :checked+.switch__toggle>.switch__handle {
          left: 55px !important;
        }
        :checked+.switch--material__toggle>.switch--material__handle::after,
        :checked+.switch__toggle>.switch__handle::after {
          content: "BTC";
        }
        .switch--material__handle:before {
          content: initial;
        }
      }
    }
    .row-amount.confirm-send {
      justify-content: flex-end;
      margin-right: 45px;
    }
    .row-amount.row-qr {
      justify-content: flex-end;
      .qr-corner-frame1 {
        padding: 3em;
        border: 0.25em solid;
        border-radius: 20px;
        border-color: #010C4C;
        position: relative;
      
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          background: #fff;
        }
        
        &::before {
          top: -0.3em;
          bottom: -0.3em;
          left: 4em;
          right: 4em;
        }
        
        &::after{
          left: -0.3em;
          right: -0.3em;
          top: 4em;
          bottom: 4em;
        }
        .qr-corner-frame2 {
          position: relative;
          z-index: 1;
          .qr-frame {
            padding: 10px;
            border-width: 1px;
            box-shadow: 0 0 6px 5px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
    .row-size.confirm-send {
      width: 40%;
    }
    .row-size.confirm-send.success-send{
      justify-content: center;
      background-color: white;
      font-size: 80px;
      color: #00FF00;
    }
    .row-size.row-qr-desc {
      width: 50% !important;
      background-color: white;
      align-self: flex-end;
      place-content: flex-end;
    }
    .circle-amount {
      width: 200px;
      height: 200px;
      background-color: #00003A;
      border-radius: 50%;
      .amount-money {
        color: white;
        font-size: 50px;
        font-weight: 600;
        line-height: 200px;
      }
    }
    .circle-amount.confirm-send {
      width: 250px;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .amount-money {
        font-size: 5rem;
        line-height: initial;
      }
      .currency {
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
      }
      .currency-black {
        color: white;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
    .circle-amount.confirm-send.success-send {
      background-color: #E6E6E6;
      .amount-money,
      .currency{
        color: black;
      }
    }
  }
  .input-amount {
    align-items: center !important;
  }
  .btn-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw !important;
    margin-bottom: 20px;
    .numpad {
      width: 65%;
      height: 75%;
      margin-bottom: 20px;
      border-radius: 5%;
      background-color: #d4d6e9;
      display: grid;
      grid-row-gap: 5px;
      .calculator-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7vw;
        cursor: pointer;
        font-size: 2rem;
        font-weight: 600;
        flex: 1;
      }
      .top-left {
        border-top-left-radius: 10px;
      }
      .top-right {
        border-top-right-radius: 10px;
      }
      .bottom-left {
        border-bottom-left-radius: 10px;
      }
      .bottom-right {
        border-bottom-right-radius: 10px;
      }
      .calculator-number:active {
        background-color: $text-gray;
      }
      .calculator-number.empty-number {
        cursor: default;
      }
      .calculator-number.empty-number:active {
        background-color: #d4d6e9;
        border-bottom-right-radius: 10px;
      }
    }
    .center-inline-button {
      margin-left: 90px;
    }
  }
  .btn-col.only-button {
    justify-content: flex-end;
  }
  .w-90 {
    margin-bottom: 20px;
  }
}

@include breakpoint($boot-desktop) {
  .tofriend-content {
    .input-col {
      .row-size.confirm-send {
        width: 30%;
        .name-option {
          margin-left: 30px;
        }
      }
      .row-size.row-qr-desc {
        width: 50% !important;
      }
    }
  }
}

@include breakpoint($sm-desktop) {
  .tofriend-content {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
    .title {
      margin-top: 20px;
    }
    .input-col {
      .row-size {
        width: 60%;
        .name-option {
          margin-left: 20px;
        }
      }
      .row-size.confirm-send {
        margin-bottom: 25px;
        width: 30%;
        .name-option {
          margin-left: 30px;
        }
      }
      .row-size.row-qr-desc {
        width: 50% !important;
      }
    }
    .btn-col .numpad {
      width: 85%;
    }
  }
}

@include breakpoint($lg-tablet) {
  .tofriend-content {
    .input-col {
      .row-size.confirm-send {
        width: 35%;
        .name-option {
          margin-left: 25px;
        }
      }
      .row-size.row-qr-desc {
        width: 50% !important;
      }
    }
  }
}

@include breakpoint($tablet) {
  .tofriend-content {
    .input-col {
      .qr-button {
        width: 60% !important;
      }
    }
    .input-col.input-qr,
    .btn-col.qr-button {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      .row-qr-desc,
      .row-qr {
        justify-content: center !important;
        width: 100% !important;
      }
      .button-blue {
        margin-left: 0 !important;
      }
    }
    .w-90 {
      width: 100% !important;
      justify-content: center !important;
    }
  }

}

@include breakpoint($lg-phone) {
  .tofriend-content {
    .input-col .row-size {
      width: 80%;
    }
  }
}

@include breakpoint($m-phone) {
  .tofriend-content {
    .input-col {
      .row-item {
        width: 80%;
        margin-right: 10%;
      }
      .row-size.confirm-send {
        width: 60%;
      }
      .row-size.row-qr-desc {
        width: 100% !important;
      }
      .qr-button {
        width: 80% !important;
      }
    }
  }
}

@include breakpoint($phone) {
  .tofriend-content {
    .input-col {
      .row-item {
        width: 80%;
        margin-right: 10%;
      }
      .value-item {
        flex: 2;
      }
    }
    .input-col,
    .btn-col {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      .numpad {
        height: 45vh;
      }
    }
  }
}

@include breakpoint($sm-phone) {
  .tofriend-content {
    .input-col {
      .row-size.confirm-send {
        width: 80%;
      }
      .circle-amount.confirm-send {
        width: 225px;
        height: 225px;
        .amount-money {
          font-size: 4rem;
        }
        .currency {
          font-size: 1.3rem;
        }
      }
      .row-size.confirm-send.success-send {
        font-size: 65px;
      }
      .row-amount.row-qr .qr-corner-frame1 {
        padding: 2em;
      }
    }
  }
}