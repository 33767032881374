// UTILS
@import "./utils/variable";
@import "./utils/mixins";
// COMPONENTS
// PAGES
@import "./pages/toFriends";
@import "./pages/exchangeBalance";
@import "./pages/getPayment";
@import "./pages/auth";
@import "./pages/processPayments";
@import "./pages/common";
@import "./pages/withdraw";


#pagination-last-page {
  display: none;
}

nav.rdt_Pagination > span {
  display: none;
}