.exchange-content {
  position: relative;
  display: flex;
  height: auto;
  min-height: calc(100vh - 204px);
  .title {
    font-size: 30px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
  }
  .input-col {
    display: flex;
    flex-direction: column;
    width: 50vw !important;
    .row-margin-top {
      margin-top: 20px !important;
    }
    .row-amount {
      justify-content: center;
      .circle-amount {
        width: 200px;
        height: 200px;
        background-color: #00003A;
        border-radius: 50%;
        .amount-money {
          color: white;
          font-size: 50px;
          font-weight: 600;
          line-height: 200px;
        }
      }
      .circle-amount.confirm-send {
        width: 250px;
        height: 250px;     
        .amount-money {
          font-size: 60px;
          line-height: 250px;
        }   
      }
      .circle-amount.confirm-send.success-send {
        background-color: #E6E6E6;
        .amount-money {
          color: black;
        }
      }
      .switch--material {
        height: 32px;
      }
      .switch {
        width: 220px !important;
        margin-bottom: 20px;
        .switch--material__toggle,
        .switch__toggle {
          margin-top: 0;
          box-shadow: inset 0 0 0 2px #d4d6e9;
          background-color: #D4D6E9;
          height: 32px;
          border-radius: 8px;
          .switch--material__handle,
          .switch__handle {
            margin-top: 0;
            top: 0 !important;
            height: 32px;
            width: 115px !important;
            right: 55px !important;
            background-color: #00003A !important;
            border-radius: 8px;
          }
          .switch__handle::after {
            content: "USD a BTC";
            color: white;
            padding-left: 15px;
            vertical-align: sub;
            font-size: 18px;
          }
        }
        .switch__toggle::after {
          content: "BTC a USD";
          padding-left: 123px;
          vertical-align: sub;
          font-size: 18px;
        }
        :checked+.switch--material__toggle::after,
        :checked+.switch__toggle::after {
          content: "USD a BTC";
          padding-left: 14px;
        }
        :checked+.switch--material__toggle>.switch--material__handle,
        :checked+.switch__toggle>.switch__handle {
          left: 120px !important;
        }
        :checked+.switch--material__toggle>.switch--material__handle::after,
        :checked+.switch__toggle>.switch__handle::after {
          content: "BTC a USD";
        }
        .switch--material__handle:before {
          content: initial;
        }
      }
    }
    .row-amount.confirm-send {
      justify-content: flex-end;
      margin-right: 45px;
    }
    .row-size {
      width: 45%;
      padding: 0.8rem;
      margin: 0.2rem;
      border-radius: 10px;
      background-color: $back-gray;
      .color-box {
        height: 30px;
        width: 30px;
      }
      .name-option {
        margin-left: 10px;
        align-self: center;
      }
    }
    .row-size.confirm-send {
      align-self: center;
    }
    .row-size.confirm-send.success-send{
      justify-content: center;
      background-color: white;
      font-size: 80px;
      color: #00FF00;
    }
    .row-size.confirm-send.conversion-result{
      justify-content: center;
      background-color: #00003A;
      font-size: 22px;
      color: white;
      width: 20%;
    }
    .row-size.row-qr-desc {
      background-color: white;
    }
  }
  .input-amount {
    align-items: center !important;
  }
  .btn-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw !important;
    margin-bottom: 20px;
    .numpad {
      width: 65%;
      height: 75%;
      margin-bottom: 20px;
      border-radius: 5%;
      background-color: #d4d6e9;
      display: grid;
      grid-row-gap: 5px;
      .calculator-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7vw;
        cursor: pointer;
        font-size: 2rem;
        font-weight: 600;
        flex: 1;
      }
      .top-left {
        border-top-left-radius: 10px;
      }
      .top-right {
        border-top-right-radius: 10px;
      }
      .bottom-left {
        border-bottom-left-radius: 10px;
      }
      .bottom-right {
        border-bottom-right-radius: 10px;
      }
      .calculator-number:active {
        background-color: $text-gray;
      }
      .calculator-number.empty-number {
        cursor: default;
      }
      .calculator-number.empty-number:active {
        background-color: #d4d6e9;
        border-bottom-right-radius: 10px;
      }
    }
    .center-inline-button {
      margin-left: 90px;
    }
  }
  .btn-col.only-button {
    justify-content: flex-end;
  }
  .w-90 {
    margin-bottom: 20px;
  }
}

@include breakpoint($sm-desktop) {
  .exchange-content {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
    .title {
      margin-top: 20px;
    }
    .input-col {
      .row-size.confirm-send.conversion-result {
        width: 25%;
      }
    }
  }
}

@include breakpoint($tablet) {
  .exchange-content {
    .w-90 {
      justify-content: center !important;
      width: 100% !important;
    }
  }
  
}

@include breakpoint($lg-phone) {
  .exchange-content {
    .input-col {
      .row-size.confirm-send.conversion-result {
        width: 35%;
      }
    }
  }
}

@include breakpoint($m-phone) {
  .exchange-content {
    .btn-col {
      .numpad {
        width: 80%;
      }
    }
  }
}

@include breakpoint($phone) {
  .exchange-content {
    .title {
      font-size: 25px;
    }
    .input-col,
    .btn-col {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      .numpad {
        height: 45vh !important;
      }
      .row-size.confirm-send.conversion-result {
        width: 60% !important;
      }
    }
  }
}

@include breakpoint($m-phone) {
  .exchange-content {
    .input-col {
      .circle-amount.confirm-send.success-send {
        width: 225px;
        height: 225px;
        .amount-money {
          font-size: 50px;
          line-height: 225px;
        }
      }
      .row-size.confirm-send.success-send {
        font-size: 65px;
      }
    }
  }
}