@mixin breakpoint($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
@mixin breakpoint-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@mixin breakpoint-height($height) {
  @media screen and (max-height: $height) {
    @content;
  }
}
