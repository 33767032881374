.sidebar {
  background-color: #010C4C;
  min-height: 100vh;
  width: 25%;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 20px);
}
.sidebar-logo {
  padding-top: 3rem;
  background-image: url("../../../assets/images/logo_white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  height: 100px;
/*   background-size: 80%;
  height: 150px; */
}

.sidebar-button {
  display: flex;
  height: 25px;
  background-color: white;
  margin: 15px 30px;
  border-radius: 25px;
  color: #010C4C;
  margin-bottom: 50px;
  min-width: fit-content;
}

#sidebar-userDni{
  margin: 0px auto;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 13px;
  color: #283090;
  align-self: center
}

@media screen and (max-width: 1130px) {
  .sidebar-button {
    padding: 8px;
  }
}

.sidebar-option {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  color: white;
  background-size: 40px;
  height: 40px;
  padding-left: 30px;
  text-align: left;
  cursor: pointer;
}

.sidebar-items{
  padding: 40px;
}

.sidebar-optImage{
  display: flex;
}

.sidebar-option:hover {
  background-color: #171f4d;
}

.sidebar-option img {
  width: 30px;
  height: 30px;
}

.sidebar-option span {
  padding-left: 1rem;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  font-size: 15px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #E6E6E6;
  border: 2px solid #010C4C;
}

.logout {
  position: absolute;
  left: 8%;
  bottom: 30px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

.sidebar-close {
  display: none;
}

@media (max-width: 992px) {
  .sidebar-wrap {
    width: 0 !important;
    transition: 0.3s;
  }
  .sidebar-wrap.visible {
    display: block;
    position: absolute;
    z-index: 10;
    width: 50% !important;
    height: 100%;
    transition: 0.3s;
    background-color: #171f4d;
  }

  .sidebar .sidebar-close {
    display: block;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: white;
    cursor: pointer;
  }

  .sidebar {
    height: 100%;
    width: 0 !important;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.9);
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
  }

  .sidebar.visible {
    width: 100% !important;
    opacity: 1;
  }
  

  .logout {
    left: 35% !important;
  }
}


@media (max-width: 525px) {
  .sidebar-wrap.visible {
    width: 65% !important;
  }
  
}

@media (max-width: 360px) {
  .sidebar-wrap.visible {
    width: 85% !important;
  }
}