.withdrawals {
  height: auto;
  min-height: calc(100vh - 204px);
}

@media (max-width: 1360px) {
  .withdrawals.table-section .header-section {
    padding: 0 2rem 2rem 2rem;
  }

  .withdrawals.table-section .wd-table-container {
    margin: 0 2rem;
  }
}

@media (max-width: 992px) {
  .withdrawals {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }
}
