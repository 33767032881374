/* The switch - the box around the slider */
.switch-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch-box input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $check-color;
}

input:focus + .slider {
  box-shadow: 0 0 1px $check-color;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 20px;
}

.switch-text-no {
  font-size: 0.8rem;
  position: absolute;
  bottom: 6px;
  left: 12px;
}
.switch-text-yes {
  font-size: 0.8rem;
  position: absolute;
  bottom: 6px;
  left: 45px;
}
.link {
  text-decoration: none !important;
}

.dashboard .square svg {
  margin-top: 10px;
}