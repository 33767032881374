.auth-container {
  .phone-input {
    background-color: rgba(40, 48, 144, 0.2) !important;
    width: 100% !important;
    height: 100%;
    border-radius: 10px !important;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    color: #686868;
  }
  .auth-form-box {
    justify-content: center;
    align-items: center;
    padding-top: 80px;
  }
  .auth-navbar {
    justify-content: flex-end;
    height: 35px;
    align-items: center;
  }

  .auth-navbar-item{
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 14px;
    text-align: center;
  }

  .btn-login{
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }

  .header-link {
    color: $white;
    margin: 0rem 2rem;
    font-weight: 500;
  }
  .margin-null {
    margin: 0rem 0rem;
  }
  .bckWhite {
    background-color: white !important;
  }
  .card-auth {
    align-self: center;
    border-radius: 13px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 25vw;
    margin-bottom: 85px;
    padding: 0px;
    background: #010c4c;
    border: 2px solid rgba(40, 48, 144, 0.37);
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgb(0, 0, 0 / 25%);

    .logo-auth {
      width: 20vw;
    }
    .margin-auth {
      margin-top: 1rem;
      border-radius: 7px;
    }
    .margin-auth-item {
      background-color: white;
      border-radius: 0px 0px 10px 10px;
      padding: 20px;
    }

    .flex-loginBody{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .login-txt{
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      text-align: center;
      color: #686868;
      margin: 0 auto;
    }
    .input-container {
      margin: 0.8rem 0;
      padding: 0.5rem;
      font-size: 1.8rem;
      background-color: #cdd2e5;
      border-radius: 10px;

      .text-input {
        font-size: 1rem;
        color: #686868;
        width: 100%;
      }
    }
    .title-register {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .subtitle-register {
      font-size: 1.2rem;
      font-size: 400;
      margin-bottom: 2rem;
    }
    .img-store {
      width: 90%;
      cursor: pointer;
    }
  }
}

.react-tel-input .form-control {
  padding: 10.5px 5px 10.5px 57px !important; 
}


@include breakpoint($desktop) {
  .auth-container {
    .auth-navbar {
      height: 75px;
    }
    .card-auth {
      width: 35vw;
      margin-bottom: 75px;
      .logo-auth {
        width: 28vw;
      }
      .input-container {
        margin: 0.6rem 0;
        padding: 0.6rem 0.6rem;
        font-size: 1.5rem;
        .text-input {
          font-size: 1.5rem;
        }
      }
    }
  }
}
@include breakpoint($sm-desktop) {
  .auth-container {
    .auth-navbar {
      height: 60px;
    }
    .header-link {
      margin: 0rem 2.4rem;
    }
    .margin-null {
      margin: 0rem 0rem;
    }
    .card-auth {
      width: 44vw;
      margin-bottom: 60px;
      .logo-auth {
        width: 36vw;
      }
      .margin-auth {
        margin-top: 1rem;
      }
      .input-container {
        margin: 0.5rem 0;
        padding: 0.5rem 0.5rem;
        font-size: 1.2rem;
        .text-input {
          font-size: 1.2rem;
        }
      }
      .title-register {
        font-size: 1.3rem;
      }
      .subtitle-register {
        font-size: 1rem;
      }
    }
  }
}
@include breakpoint($lg-phone) {
  .auth-container {
    .auth-navbar {
      height: 45px;
    }
    .header-link {
      margin: 0rem 1.8rem;
    }
    .margin-null {
      margin: 0rem 0rem;
    }
    .card-auth {
      width: 54vw;
      margin-bottom: 45px;
      padding-top: 2rem;

      .margin-auth {
        margin-top: 1rem;
      }

      .logo-auth {
        width: 46vw;
      }
      .input-container {
        margin: 0.4rem 0;
        padding: 0.4rem 0.4rem;
        font-size: 1rem;
        .text-input {
          font-size: 1rem;
        }
      }
      .img-store {
        width: 100%;
      }
      .title-register {
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
      }
      .subtitle-register {
        font-size: 0.8rem;
        margin-bottom: 1.5rem;
      }
    }
    .text-size {
      font-size: 0.8rem;
    }
  }
}

@include breakpoint($m-phone){
  .auth-container{
    .btn-login{
      font-size: 13px;
      line-height: 38px;
    }
  }
}


@include breakpoint($phone) {
  .auth-container {
    .btn-login{
      font-size: 13px;
      line-height: 38px;
    }
    .auth-navbar-item{
      font-size: 14px;
    }
    .card-auth {
      width: 66vw;
      margin-bottom: 45px;
      padding-top: 2rem;
      padding-bottom: 0;
      .logo-auth {
        width: 50vw;
      }
      .input-container {
        margin: 0.4rem 0;
        padding: 0.4rem 0.4rem;
        font-size: 1rem;
        .text-input {
          font-size: 1rem;
        }
      }
      .title-register {
        font-size: 1rem;
        margin-bottom: 1.3rem;
      }
      .subtitle-register {
        font-size: 0.7rem;
        margin-bottom: 1.3rem;
      }
    }
    .text-size {
      font-size: 0.7rem;
    }
  }
}


@include breakpoint($sm-phone) {
  .auth-container {
    .btn-login{
      font-size: 12px;
      line-height: 35px;
    }
    .card-auth {
      width: 66vw;
      margin-bottom: 45px;
      padding-top: 2rem;
      padding-bottom: 0;
      .margin-auth-item{
        padding: 10px;
      }
      .logo-auth {
        width: 50vw;
      }
      .input-container {
        margin: 0.4rem 0;
        padding: 0.4rem 0.4rem;
        font-size: 1rem;
        .text-input {
          font-size: 1rem;
        }
      }
      .title-register {
        font-size: 1rem;
        margin-bottom: 1.3rem;
      }
      .subtitle-register {
        font-size: 0.7rem;
        margin-bottom: 1.3rem;
      }
    }
    .text-size {
      font-size: 0.7rem;
    }
  }
}
@media screen and (max-width: $lg-tablet) and (orientation: portrait) {
  .auth-container {
    display: flex;
    align-items: center;
    .auth-navbar {
      position: fixed;
      top: 0;
    }
  }
}
