.content-settings {
  height: auto;
  min-height: calc(100vh - 204px);
}

.content-settings .content-settings-header {
  display: flex;
}

.content-settings .content-settings-header .title-section {
  width: 35%;
}

.content-settings .content-settings-header .available-section-wrapper {
  display: flex;
  flex: 1;
}

.content-settings .content-settings-header .available-section,
.content-settings .content-settings-header .withdrawals-section {
  flex: 1;
  width: 20%;
  align-self: center;
}


@media only screen and (max-width: 1400px) {
  .content-settings .content-settings-header {
    flex-direction: column;
  }
  .content-settings .content-settings-header .available-section-wrapper {
    margin-left: 70px;
  }
}

.content-settings .content-settings-header .available-section .available-title {
  color: rgba(0, 0, 0, 0.6);
}
.content-settings .content-settings-header .available-section .available-amount {
  font-weight: 600;
}

.content-settings .content-settings-header .withdrawals-button {
  float: right;
  width: 70%;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  height: 30px;
  padding-top: 10px;
  margin-right: 40px;
  cursor: pointer;
}

.content-settings .content-settings-header .withdrawals-button svg {
  margin-right: 10px;
  vertical-align: middle;
}

.content-settings .content-title {
  height: 40px;
  width: 215px;
  background: #010C4C;
  border-radius: 10px;
  margin: 15px 70px;
  color:white;
  font-size: 25px;
}

.content-settings .content-subtitle {
  width: 310px;
  height: 25px;
  background: #E6E6E6;
  color: black;
  font-size: 17px;
}

.content-settings .card-switch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: 200px;
  border-radius: 15px;
  vertical-align: top;
  margin-right: 12px;
}

.content-settings .switch-title {
  margin: 25px auto;
  width: 67%;
  font-size: 20px;
}

.content-settings :checked+.switch__toggle {
  border-radius: 10px !important;
  background-color: #666666 !important;
  box-shadow: inset 0 0 0 2px #666666 !important;
  width: 75px !important;
}

.content-settings .switch__toggle {
  border-radius: 10px !important;
  background-color: #e5e5e5 !important;
  width: 75px !important;
}

.content-settings .switch--material__toggle {
  margin-top: 0;
  height: auto;
}

.error {
   border: 1px solid red;
   border-radius: 20px;
   height: 60px;
  }

  .error-message {
    color: red;
    font-size: 13px;
    padding: 5px;
  }

.content-settings .card-description {
  padding: 15px 0;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 55%;
  border: 1px solid #010C4C;
  color: #a9a9a9;
  border-radius: 15px;
}

.content-settings .card-description .title {
  font-weight: 600;
  color: #010C4C;
  padding-bottom: 1rem;
}

.content-settings .card-description p {
  padding: 0 29px;
  margin: 0;
}

.content-settings :checked+.switch__toggle>.switch__handle {
  border-radius: 5px !important;
  width: 30px !important;
  top: 3px !important;
  left: 40px !important;
}

.content-settings .switch__handle {
  border-radius: 5px !important;
  width: 30px !important;
  top: 3px !important;
  left: 5px !important;
}

.content-settings .switch--material__handle {
  background-color: #f1f1f1 !important;
  margin-top: 0;
  height: 28px;
}

.content-settings .switch__touch {
  top: 0;
  bottom: 0;
}

.content-settings .switch__touch::after {
  content: "No";
  padding-left: 14px;
  vertical-align: middle;
  color: #666666 !important;
  font-size: 15px;
}

.content-settings :checked+.switch__toggle>.switch__handle>.switch__touch::after {
  content: "Si";
  padding-left: 20px;
  vertical-align: middle;
  color: #666666 !important;
  font-size: 15px;
}

.content-settings .terminals-form {
  margin: 5px 0;
}

.content-settings .form-row {
  padding: 0 40px;
  justify-content: space-evenly;
}

.content-settings .form-col-input {
  background-color: #E6E6E6;
  border-radius: 10px;
  width: 95%;
  height: 50px;
  padding: 5px 0;
}

.last-withdrawal-btn{
  margin-left: 60%;
  height: 25px;
  border: 1px solid #CCCCCC;
  padding: 11px;
}

.content-settings .action {
  background-color: #E6E6E6;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  padding: 5px 0;
  margin: 0 0.5rem;
  font-size: 1rem;
}

.content-settings .action.blue-action {
  background: #010C4C;
  color: white;
  width: 90%;
  font-size: 15px;
  cursor: pointer;
}

.content-settings .action.blue-action.blue-inactive {
  background: #4C5590;
  cursor: initial;
}

.content-settings .form-label {
  text-align: start;
  margin-left: 10px;
}

.content-settings .blue-action .form-label.form-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content-settings .form-label.form-icon {
  text-align: center;
  margin-left: 0;
}

.content-settings .blue-action .form-label.form-icon svg {
  margin-right: 10px;
}

.content-settings .form-input {
  font-size: 2rem;
  background-color: #E6E6E6;
  border-radius: 10px;
  width: 90%;
}

.content-settings .form-cell {
  font-size: 15px;
  background-color: #E6E6E6;
  border-radius: 10px;
}

.content-settings .form-input input {
  text-align: center;
  font-size: 17px !important;
}

.content-settings input.form-input {
  text-align: center;
  margin-top: 5px;
  font-size: 17px !important;
  border: none
}


.content-settings .form-input .text-input {
  color: black;
  font-size: 15px;
}

.content-settings .form-col-delete {
  font-size: 25px;
  color: #010C4C;
  margin-top: 12px;
  cursor: pointer;
}

.content-settings .button-add {
  padding-left: 55px;
  padding-right: 30px;
  height: 45px;
  padding-top: 7px;
  margin-bottom: 25px;
  width: fit-content;
}

.content-settings .button-icon {
  position: absolute;
  left: 20px;
}

.content-settings .form-col-loading {
  font-size: 20px;
  margin-top: 15px;
}

.content-settings .form-col-input.col-addaccount {
  height: 60px;
  margin-bottom: 25px;
  border: 1px solid transparent;
}

.content-settings .form-col-input.col-addaccount.is-required {
  border: 1px solid red;
}

.content-settings .form-col-input.col-addaccount select {
  margin-top: 12px;
  text-align-last: center;
}

.content-settings .form-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.content-settings .form-buttons .button {
  width: 245px !important;
  padding-top: 5px !important;
  height: 40px !important;
}

.content-settings .card-section {
  display: flex;
  flex-direction: row;
  padding: 0 150px;
}

@media (max-width: 1090px) {
  .content-settings .form-cell {
    /*font-size: 18px;*/
  }
  .content-settings .status-label {
    /*font-size: 18px;*/
  }
  .content-settings .form-col-delete {
    /*font-size: 20px;*/
  }
}

@media (max-width: 992px) {  
  .content-settings {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .content-settings .form-cell {
    font-size: 16px;
  }
  .content-settings .status-label {
    font-size: 16px;
  }
}

@media (max-width: 660px) {
  .content-settings .form-cell {
    font-size: 14px;
  }
  .content-settings .content-title {
    font-size: 22px;
  }
  .content-settings .content-subtitle {
    font-size: 16px;
  }
  .content-settings .switch-title {
    font-size: 18px;
  }
  .content-settings .card-description p {
    font-size: 14px;
    padding: 0 15px;
  }
  .content-settings .button-icon {
    font-size: 25px !important;
  }
}

@media (max-width: 1280px) {
  .content-settings .form-row {
    padding: 0 0;
  }
  .content-settings .switch-title {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .content-settings .form-row {
    padding: 0 0;
  }
  .content-settings .switch-title {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .content-settings div:nth-child(5) {
    border-radius: initial;
  }
  .content-settings .crLLAg {
    padding-right: 0;
  }
  .content-settings .form-col-loading {
    font-size: 18px;
  }
  .content-settings .card-description p {
    font-size: 12px;
    padding: 0 0;
  }
  .content-settings .switch-title {
    font-size: 14px;
  }
  .content-settings .content-title {
    margin: 15px 30px;
  }
  .content-settings .card-section {
    padding: 0 30px;
  }
  .content-settings .content-subtitle {
    width: auto;
  }
  .content-settings .card-switch {
    width: 50%;
  }
  .content-settings .card-description {
    width: 45%;
  }
  .content-settings .terminals-form.add-form ons-col {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
  .content-settings .card-switch {
    width: 100%;
  }
  .content-settings .card-description {
    width: 100%;
  }
}

@media (max-width: 370px) {
  .content-settings .button-add {
    font-size: 12px;
  }
  .content-settings .button-icon {
    font-size: 20px !important;
  }
  .content-settings .content-title {
   font-size: 18px;
  }
  .content-settings .content-subtitle {
    font-size: 12px;
  }
  .content-settings .select-input {
    font-size: 14px;
  }
}
