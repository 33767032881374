// SIZE SCREENS

$sm-phone: 370px;
$phone: 480px;
$m-phone: 576px;
$lg-phone: 660px;
$sm-tablet: 767px;
$tablet: 992px;
$lg-tablet: 1024px;
$sm-desktop: 1090px;
$boot-desktop: 1200px;
$m-desktop: 1270px;
$desktop: 1482px;
$lg-desktop: 1920px;

// COLORS APP

$back-blue: #010c4c;
$white: white;
$back-gray: #e6e6e6;
$green-money: #39b54b;
$text-gray: #969696;
$check-color :#283090;;
$black: black;
