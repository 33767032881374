.withdraw-content {
  position: relative;
  display: grid;
  height: auto;
  min-height: calc(100vh - 204px);

  @media (max-width: 992px) {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }

  .withdraw-content-header {
    display: flex;
    width: 70%;
    .withdraw-title,
    .available-section {
      flex: 1;
    }
    @media (min-width: 991px) {
      .available-section{
        .available-section {
        color: rgba(0, 0, 0, 0.6);
      }
      .available-title {
         color: rgba(0, 0, 0, 0.6)
      }
      }
      .withdraw-title {
        color: rgba(0, 0, 0, 0.6);
      }
      .available-amount {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
      }
      }
    }
    .withdraw-title {
      font-weight: 600;
      padding-top: 18px;
      font-size: 18px;
    }
  }
  .withdraw-content-inputs {
    display: flex;
    width: 100%;
    padding: 0 50px;
    .form-input {
      flex: 1;
      margin: 0 15px;
      height: 42px;
    }
    .button-blue {
      flex: 1;
      margin: 0 20px;
    }
  }
  ons-row {
    height: fit-content;
  }
  .withdraw-content-container {
    .withdraw-card {
      border-width: 1px;
      border-radius: 10px;
      box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.2);
      vertical-align: top;
      width: 50%;
      margin: 60px auto;
      text-align: center;
      text-align: -webkit-center;
      padding: 20px 0;
      .mv-3 {
        margin: 3rem 0;
      }
      @media (min-width: 991px) {
        .withdraw-confirm-title {
          color: rgba(0, 0, 0, 0.6);
        }
        .withdraw-confirm-account,
        .withdraw-confirm-bank {
          color: rgba(0, 0, 0, 0.6);
          font-weight: 600;
        }
        .withdraw-title,
        .available-title {
          color: rgba(0, 0, 0, 0.6);
        }
      }
      .withdraw-confirm-title {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .withdraw-amount {
        display: table-cell;
        vertical-align: middle;
        padding-top: 0 !important;
        font-size: 18px;
      }
      .withdraw-confirm-account {
        margin: 20px 0 10px 0;
      }
      .withdraw-confirm-bank {
        margin-bottom: 40px;
      }
      .withdraw-confirm-button {
        height: 30px;
        font-size: larger;
        background-color: #172eb4;
        display: table-cell;
        vertical-align: middle;
        width: 60%;
      }
      .withdraw-confirm-button.loading {
        width: 10% !important;
      }
      .withdraw-success-button {
        width: 15% !important;
      }
      .withdraw-success-icon {
        color: #1ecd28;
        font-size: 100px;
        margin-top: 20px;
      }
      .withdraw-success-title {
        font-weight: 600;
      }
    }
  }
